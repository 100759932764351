<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:title>
      <v-row class="ma-1">
        <v-btn-toggle v-model="period" mandatory borderless dense class="ml-1" @click="onChangePeriod">
          <v-btn value="1M" @click="onChangePeriod('1M')">
            <span class1="hidden-md-and-down">1 месяц</span>
          </v-btn>
          <v-btn value="3M" class="mx-1" @click="onChangePeriod('3M')">
            <span class1="hidden-md-and-down">3 месяца</span>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </template>
    <v-row>
      <v-col v-if="loaded" cols="12" :sm="6" class1="pa-2">
        <v-card class="ma-0" height="532">
          <v-card-title class="primary white--text py-0">Статистика</v-card-title>
          <a-table-f-data2 ref="table" :dataTable="usersList" :model="model" :useQuery="false" :searchable="false" @setCheckbox="setCheckbox" @click="clickRow($event)" />
        </v-card>
      </v-col>
      <v-col v-if="loaded" cols="12" :sm="6" class1="pa-2">
        <Item
          :period="{ value: period, start: dateStart, end: dateEnd, days, usersList }"
          :data="data"
          :dataP="dataP"
          :user="{ id: -1, name: 'Общий' }"
          @showDetail="onShowDetail({ id: -1, name: 'Общий' }, $event)"
        />
      </v-col>
      <v-col v-for="user of users" :key="user.id" cols="12" :sm="users.length > 1 ? 6 : 6">
        <Item
          :period="{ value: period, start: dateStart, end: dateEnd, days, usersList }"
          :data="data"
          :dataP="dataP"
          :user="user"
          @showDetail="onShowDetail(user, $event)"
        />
      </v-col>
    </v-row>
    <portal to="v-main"> <Detail v-model="dialog" :data="selectedData" /> </portal
  ></static-fullscreen-card>
</template>

<script>
import "splitpanes/dist/splitpanes.css";
export default {
  components: {
    Detail: () => import("./detail"),
    Item: () => import("./item"),
    Panel: () => import("./panel"),
  },

  data() {
    return {
      selectedData: {},
      dialog: false,
      usersList: [],
      loaded: false,
      users: [],
      period: "1M",
      dateStart: null,
      dateEnd: null,
      days: 0,
      url: "/mechti/construction-schedule",
      model: [
        { name: "checked", title: "", type: "checkbox", width: 35 },
        { name: "name", title: "", type: "string", width: 200 },
        { name: "works", title: "Всего", type: "number", width: 80 },
        { name: "done", title: "Выполнено", type: "number", width: 80 },
        { name: "wait", title: "В работе", type: "number", width: 80 },
      ],

      dates: [],
      result: [],
      works: [],
      late: [],
      data: null,
      dataP: null,
    };
  },
  created() {
    this.$root.title = "Анализ работ";
    this.onChangePeriod(this.period);
  },
  computed: {
    dir_user() {
      return this.$root.dirs?.user || [];
    },
  },
  watch: {
    period(v) {
      //this.fetchData(v);
    },
    users1(newVal, oldVal) {
      // Найти добавленные элементы
      const addedItems = newVal.filter((item) => !oldVal.includes(item));

      // Найти удаленные элементы
      const removedItems = oldVal.filter((item) => !newVal.includes(item));

      // Обработка добавленных и удаленных элементов
      if (addedItems.length) {
        console.log("Добавлены:", addedItems);
      }
      if (removedItems.length) {
        console.log("Удалены:", removedItems);
      }
    },
  },
  methods: {
    setCheckbox(e) {
      console.log(e, e.length);
      this.users = e;
    },
    clickRow(d) {
      if (d.field.name == "checked") return;

      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row, d.key);
      this.$refs.table.$refs.base.$forceUpdate();
      if (false)
        this.users = [
          ...this.$refs.table.data.filter((el) => {
            return el?.checked;
          }),
        ];
      console.log(d.field.name, this.users);
    },
    async fetchData(v) {
      this.loaded = false;
      this.dates = [];
      this.result = [];
      this.works = [];
      this.late = [];
      //const { ds, de } = { ds: new Date().addDays(-v * 30).date, de: new Date().addDays(1).date };
      console.log(this.dateStart, this.dateEnd);
      let params = {
        filters: {
          date_start: { condition: ">=", value: this.dateStart },
          date_end: { condition: "<=", value: this.dateEnd },
          status: [100, 101, 102, 103, 104, 105],
        },
        sort: [{ key: "id", order: "ASC" }],
      };
      let resp = await this.$axios.get(this.url, { params });
      let data = resp.data.data;
      params = {
        filters: {
          date_start: { condition: ">=", value: new Date(this.dateStart).addDays(-this.days).date },
          date_end: { condition: "<=", value: new Date(this.dateEnd).addDays(-this.days).date },
          status: [100, 101, 102, 103, 104, 105],
        },
        sort: [{ key: "id", order: "ASC" }],
      };
      let respP = await this.$axios.get(this.url, { params });
      let dataP = respP.data.data;
      this.doAnalysis(data, dataP);
    },

    doAnalysis(data, dataP) {
      /**
       *
        { value: 100, type: "work", text: "Новая работа" },
        { value: 101, type: "work", text: "В работе" },
        { value: 103, text: "В акте (черновик)" },
        { value: 104, text: "В акте (отправлен)" },
        { value: 105, text: "В отчете по мебели" },
        { value: 102,  text: "Выполнена" },
        { value: 199, text: "Отменено" },
       */
      let status = [104, 102, 105, 101]; //закуплен в заказе
      const findLastByPriority = (arr, priorities) => {
        let a = [...arr].reverse();
        for (let s of priorities) {
          const found = a.find((element) => element.value === s);
          if (found) return found;
        }
        return null; // Если ничего не найдено
      };
      let users = {};
      data.map((d) => {
        let done = d.status_history.find((h) => status.includes(h.value) && [102, 104, 105].includes(d.status))?.createdon || null;
        let user_id = findLastByPriority(d.status_history, [/*105, 104, 103,*/ 101])?.createdby_id || "error";
        d.user_id = user_id;
        d.user_name = user_id == "error" ? "Не определено" : (this.dir_user.find((u) => u.value === user_id) || {})?.text;
        if (!users?.[d.user_id]) users[d.user_id] = { id: d.user_id, name: d.user_name, works: 0, done: 0, wait: 0, checked: false };
        if (done) {
          if (done > this.dateStart) {
            users[d.user_id].done++;
            users[d.user_id].wait--;
          } else {
            users[d.user_id].works--;
          }
          d.date_done = done ? new Date(done).date : null;
        }
        users[d.user_id].wait++;
        users[d.user_id].works++;
      });
      dataP.map((d) => {
        let done = d.status_history.find((h) => status.includes(h.value) && [102, 104, 105].includes(d.status))?.createdon || null;
        d.date_done = done && new Date(done).date;
      });
      this.usersList = Object.values(users);
      //  this.usersList.unshift({ id: -1, name: "Общий" });
      this.data = data;
      this.dataP = dataP;
      this.loaded = true;
      console.log(this.loaded);
    },

    async onChangePeriod(e) {
      this.users = [];
      e = e || this.period;
      if (e == "3M") {
        this.dateStart = new Date().addDays(-90).date;
        this.dateEnd = new Date().date;
        this.days = 90;
      }
      if (e == "1M") {
        this.dateStart = new Date().addDays(-30).date;
        this.dateEnd = new Date().date;
        this.days = 30;
      }
      if (e == "select") {
        return;
        console.log(res);
        this.paramsApi.params.ds = new Date().date;
        this.paramsApi.params.de = new Date().addDays(30).date;
      }
      this.fetchData();

      //this.$refs.table.updateData();
    },
    onSetPeriod(e) {
      console.log(e);
      this.dateStart = new Date(e.ds).date;
      this.dateEnd = new Date(e.de).date;
      this.setTitle();
    },
    onShowDetail(user, data) {
      // Получение данных выбранного столбца
      this.selectedData = data;

      // Показ диалогового окна
      this.dialog = true;
    },
  },
};
</script>
